.room-tile-container {
    @apply flex w-full relative justify-between items-center py-2 hover:bg-gray-200 rounded-sm px-4 cursor-pointer
}

.room-tile-avatar-container {
    @apply w-full flex items-center gap-x-4;
}

.room-list-avatar-content {
    @apply flex flex-col text-sm text-[#424242] leading-none;
}

.room-list-avatar-message {
    @apply text-sm text-gray-500;
}