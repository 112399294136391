.tab {
  @apply cursor-pointer flex flex-col items-center w-full px-3 py-2 text-gray-400 transition-all duration-150;
}

.tab-active {
    @apply border-l-2 border-l-[#5b5fc7] hover:border-l-[#4f52b2];
}

.tab-container {
    @apply py-1 my-1 flex gap-1;
}

.tab-btn {
    @apply hover:border-l-2 hover:text-gray-600 hover:border-gray-300;
}

.tab-icon {
    @apply w-6 h-6;
}

.tab-icon-active svg {
    @apply text-[#5b5fc7] hover:text-[#4f52b2];
}

.tab-title {
    @apply text-[#242424] text-xs;
}