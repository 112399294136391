.create-room-modal-container {
  @apply fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full flex justify-center items-center bg-gray-950/40;
}

.create-room-modal-content {
  @apply relative w-full max-w-2xl max-h-full bg-white rounded-lg shadow-md;
}

.create-room-modal-header {
  @apply flex items-start justify-between p-4 border-b rounded-t;
}

.create-room-modal-title {
  @apply text-xl font-semibold text-gray-900;
}

.create-room-modal-close-btn {
  @apply text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white;
}

.create-room-modal-body {
  @apply p-6 space-y-6;
}

.create-room-modal-input-field {
  @apply py-2 indent-3 border outline-none border-[#ddd] focus:border-indigo-400 w-full rounded;
}

.create-room-modal-footer {
  @apply flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600;
}

.create-room-modal-button-primary {
  @apply text-white bg-[#7269ef] transition-all duration-200 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center;
}

.create-room-modal-button-secondary {
  @apply text-gray-500 bg-white hover:bg-gray-100 focus:ring-2 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900;
}

.create-room-modal-cancel-icon {
    @apply h-5 w-5;
}