.login-header-container {
  @apply space-y-3 mb-9 flex flex-col justify-center items-center;
}

.login-header-title {
  @apply text-2xl text-[#343a40];
}

.login-header-text {
  @apply text-[#7a7f9a];
}
